import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';

export function addLights(scene) {
    scene.add(new THREE.HemisphereLight(0xffeed1, 0x7f7768, 0.75));
}

export function loadModel(content, type, materialProps) {
    let loader;

    switch (type) {
        case "stl": {
            loader = new STLLoader();
            break;
        }
        case "ply": {
            loader = new PLYLoader();
            break;
        }
        case "obj": {
            loader = new OBJLoader();
            break;
        }
        default: {
            throw new Error("unsupported file type");
        }
    }

    let modelOrGeometry = loader.parse(content);
    let material = new THREE.MeshStandardMaterial(materialProps);

    let mesh;

    switch (type) {
        case "stl": {
            modelOrGeometry.computeVertexNormals()
            mesh = new THREE.Mesh(modelOrGeometry, material)
            break;
        }
        case "ply": {
            modelOrGeometry.computeVertexNormals()
            mesh = new THREE.Mesh(modelOrGeometry, material)
            break;
        }
        case "obj": {
            mesh = modelOrGeometry
            break;
        }
    }

    return mesh;
}
